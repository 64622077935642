import React from 'react'
import {Link} from 'gatsby'
import BlogCard from "../BlogContent/BlogCard"

const OurBlog = ({lastPosts}) => {
    return (
      <section className="blog-area pb-70 bg-f1f8fb">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Aktuelles</span>
            <h2><span style={{ fontWeight: 300, fontSize: "0.8em" }}>LOGENTIS</span> <span
              style={{ fontFamily: "Darkline", fontSize: "2em", fontWeight: 400 }}>Journal</span></h2>
            <p>Schauen Sie doch auch in unserem <Link className={"text-primary"} style={{ fontWeight: 600 }}
                                                      to={"/lexikon/"}>Lexikon</Link> vorbei</p>
          </div>

          <div>
            {lastPosts.map(({ node: post }) => (
              <BlogCard key={post.id} post={post} />
            ))}
          </div>

          <Link to="/journal/" className="btn btn-primary" style={{ textDecoration: "none" }}>Alle Artikel</Link>
        </div>
      </section>
    );
}

export default OurBlog
